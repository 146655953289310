import React, { useMemo } from 'react';
import Head from 'next/head';
import nookies from 'nookies';
import getConfig from 'next/config';
import { datadogRum } from '@datadog/browser-rum-slim';
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import Router from 'next/router';

import GlobalContextProvider from '../components/Context/Context';
import '../styles.css';
import { AUTH0_SCOPE, REDIRECT_URL } from '../constants/index';
import EventTrackingProvider from '../components/EventTrackingProvider/EventTrackingProvider';
import SplitContextProvider from '../SplitContext/splitConfiguration';
import AppContextProvider from '../lib/contextProviders/appContextProvider';
import UserContextProvider from '../lib/contextProviders/userContextProvider';
import CartContextProvider from '../lib/contextProviders/cartContextProvider';

const {
  publicRuntimeConfig: { ENVIRONMENT, DD_CLIENT_TOKEN, DD_APPLICATION_ID },
} = getConfig();

if (typeof window !== 'undefined') {
  datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'gifting-web',
    env: ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
  });
}

const onRedirectCallback = (appState) => {
  const { [REDIRECT_URL]: redirectUrl } = nookies.get();
  if (redirectUrl) {
    Router.replace(redirectUrl);
  }
  // Use Next.js Router.replace method to replace the url test
  Router.replace(appState?.returnTo || '/');
};

function MyApp({ Component, pageProps }) {
  const getRedirectUrl = useMemo(() => {
    if (process.env.IS_SERVER) {
      return '';
    }
    return window.location.origin;
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <>
      <Head>
        <title>FairPrice Gifts</title>
      </Head>
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        redirectUri={getRedirectUrl}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        audience={process.env.AUTH0_AUDIENCE}
        scope={AUTH0_SCOPE}
        cacheLocation="localstorage"
      >
        <NdsThemeProvider data-testid="app">
          <AppContextProvider>
            <UserContextProvider>
              <GlobalContextProvider>
                <SplitContextProvider>
                  <EventTrackingProvider>
                    <CartContextProvider>
                      <Component {...pageProps} />
                    </CartContextProvider>
                  </EventTrackingProvider>
                </SplitContextProvider>
              </GlobalContextProvider>
            </UserContextProvider>
          </AppContextProvider>
        </NdsThemeProvider>
      </Auth0Provider>
    </>
  );
}

export default MyApp;
