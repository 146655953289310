import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import nookies from 'nookies';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import userReducer, { userInitialState } from './reducers/userReducer';
import UserContext from './contexts/userContext';
import {
  AUTH0_SCOPE,
  AUTH_TOKEN_KEY,
  GUEST_DATA,
  REFRESH_BEFORE_EXPIRY,
  TOKEN_UPDATE_INTERVAL,
} from '../../constants';
import { createGuest, extendGuest, getCustomerDetails } from '../../services/api/api';
import { deleteCookie, safeJSONParse, setCookie } from '../utils';

export default function UserContextProvider({ children }) {
  const [userState, dispatch] = useReducer(userReducer, userInitialState);

  const { auth0Token, isLoggingIn } = userState;
  const { isAuthenticated, isLoading: isAuthenticating, getAccessTokenSilently } = useAuth0();
  const { [AUTH_TOKEN_KEY]: authTokenFromCookie } = nookies.get();

  const existingGuest = useMemo(() => {
    const guestData = nookies.get()[GUEST_DATA];
    return isEmpty(guestData) ? undefined : safeJSONParse(guestData);
  }, []);

  const initGuestSession = useCallback(async () => {
    const guestId = existingGuest?.id;
    const guestRequestPayload = {
      ip: '127.0.0.1',
      userAgent: {
        name: navigator.appCodeName,
        browser: navigator.appName,
        version: navigator.appVersion,
        cookies: navigator.cookieEnabled,
        language: navigator.language,
        online: navigator.onLine,
        platform: navigator.platform,
        header: navigator.userAgent,
        os: guestId && 'MacOS',
      },
    };

    const response = guestId
      ? await extendGuest(guestId, guestRequestPayload)
      : await createGuest(guestRequestPayload);

    const guestData = guestId
      ? { ...response.data, signature: existingGuest?.signature }
      : response?.data || [];

    setCookie(GUEST_DATA, JSON.stringify(guestData));

    dispatch({
      type: 'SET_GUEST',
      payload: guestData,
    });
  }, [existingGuest]);

  const logUserIn = useCallback(async () => {
    async function getAuthTokenFromAuth0() {
      let accessToken = await getAccessTokenSilently({
        audience: process.env.AUTH0_AUDIENCE,
        scope: AUTH0_SCOPE,
      });
      const decodedToken = jwtDecode(accessToken);
      const id = decodedToken['https://claims.identity.nedigital.sg/association/fairprice'];
      if (!id) {
        accessToken = await getAccessTokenSilently({
          audience: process.env.AUTH0_AUDIENCE,
          scope: AUTH0_SCOPE,
          ignoreCache: true,
        });
      }
      return accessToken;
    }

    async function deriveAuthToken() {
      if (isAuthenticated) {
        const accessToken = await getAuthTokenFromAuth0();
        setCookie(AUTH_TOKEN_KEY, accessToken);
        return accessToken;
      }
      if (authTokenFromCookie) {
        return authTokenFromCookie;
      }
      return null;
    }

    async function getUserData(authenticationToken) {
      try {
        if (authenticationToken === '') {
          return null;
        }

        const {
          data: { customerId, email, name, entities },
        } = await getCustomerDetails(authenticationToken);

        return {
          id: customerId,
          email,
          name,
          entityData: entities?.[0] ?? {},
        };
      } catch (e) {
        return null;
      }
    }

    const authToken = await deriveAuthToken();
    const userData = await getUserData(authToken);

    if (userData) {
      dispatch({
        type: 'USER_LOGIN_SUCCESS',
        payload: {
          auth0Token: authToken,
          user: userData,
        },
      });
      deleteCookie(GUEST_DATA);
    } else {
      dispatch({ type: 'USER_LOGIN_FAILURE' });
      initGuestSession();
    }
  }, [authTokenFromCookie, getAccessTokenSilently, initGuestSession, isAuthenticated]);

  const setGuest = useCallback((guestData) => {
    dispatch({
      type: 'SET_GUEST',
      payload: guestData,
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticating) {
      logUserIn();
    }
  }, [isAuthenticating, logUserIn]);

  const refreshAuthToken = useCallback(async () => {
    if (auth0Token) {
      const decodedToken = jwtDecode(auth0Token);
      const { exp } = decodedToken;
      const isTokenNearExpiry = exp && exp - new Date().getTime() < REFRESH_BEFORE_EXPIRY;
      if (isTokenNearExpiry) {
        try {
          const newAuthToken = await getAccessTokenSilently({
            audience: process.env.AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
            ignoreCache: true,
          });
          dispatch({ type: 'SET_AUTH0_TOKEN', payload: newAuthToken });
          setCookie(AUTH_TOKEN_KEY, newAuthToken);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to refresh token:', error);
        }
      }
    }
  }, [auth0Token, getAccessTokenSilently]);

  useEffect(() => {
    const tokenInterval = setInterval(() => {
      refreshAuthToken();
    }, TOKEN_UPDATE_INTERVAL);
    return () => clearInterval(tokenInterval);
  }, [auth0Token, getAccessTokenSilently, refreshAuthToken]);

  useEffect(() => {
    refreshAuthToken();
  }, [refreshAuthToken]);

  return (
    <UserContext.Provider
      value={{ userState: { ...userState, isLoggedIn: !!userState.user, isLoggingIn }, setGuest }}
    >
      {children}
    </UserContext.Provider>
  );
}
