const CHECKOUT_PAYMENT_METHODS = {
  ONLINE: {
    text: 'Credit/Debit Card',
    value: 'ONLINE',
  },
  GEBIZ: {
    text: 'GeBIZ',
    value: 'GEBIZ',
  },
  EINVOICE: {
    text: 'Vendors@Gov',
    value: 'EINVOICE',
  },
  CREDITTERM: {
    text: 'Credit term',
    value: 'CREDITTERM',
  },
  GOOGLE_PAY: {
    text: 'Google Pay',
    value: 'GOOGLE_PAY',
  },
  APPLE_PAY: {
    text: 'Apple Pay',
    value: 'APPLE_PAY',
  },
  CORPBILLING: {
    text: 'QuickBuy@SGov',
    value: 'CORPBILLING',
  },
};

const SELECT_OPTIONS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'birthday',
    label: 'Birthday',
  },
  {
    value: 'congrats',
    label: 'Congratulations',
  },
  {
    value: 'thanks',
    label: 'Thank you',
  },
  {
    value: 'generic',
    label: 'Just because',
  },
  {
    value: 'christmas',
    label: "Season's greetings",
  },
];

const B2B_SELECT_OPTIONS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'appreciation',
    label: 'Show appreciation',
  },
  {
    value: 'anniversary',
    label: 'Job anniversary',
  },
  {
    value: 'congrats',
    label: 'Congratulations',
  },
  {
    value: 'farewell',
    label: 'Farewell',
  },
  {
    value: 'event',
    label: 'Your event',
  },
];

module.exports = {
  CHECKOUT_PAYMENT_METHODS,
  SELECT_OPTIONS,
  B2B_SELECT_OPTIONS,
};
