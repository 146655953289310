import React, { useLayoutEffect, useEffect, useReducer } from 'react';

import { useRouter } from 'next/router';

import appReducer, { appInitialState } from './reducers/appReducer';
import AppContext from './contexts/appContext';
import { breakpoints } from '../mediaQuery';
import { setCookie } from '../utils';
import { B2B, B2C, SITE_TYPE } from '../../constants';

export default function AppContextProvider({ children }) {
  const [appState, dispatch] = useReducer(appReducer, appInitialState);
  const { replace } = useRouter();

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoints.tablet}px)`);

    const handleMediaChange = (e) => {
      dispatch({ type: 'SET_IS_MOBILE', payload: e.matches });
    };

    mediaQuery.addEventListener('change', handleMediaChange);
    handleMediaChange(mediaQuery); // Initial check

    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  useEffect(() => {
    if (window.location.search.includes(`type=${B2B}`)) {
      setCookie(SITE_TYPE, B2B);
      dispatch({ type: 'SET_SITE_TYPE', payload: B2B });
      replace('/');
    } else if (window.location.search.includes(`type=${B2C}`)) {
      setCookie(SITE_TYPE, B2C);
      dispatch({ type: 'SET_SITE_TYPE', payload: B2C });
      replace('/');
    }
  }, [replace]);

  return <AppContext.Provider value={{ appState }}>{children}</AppContext.Provider>;
}
