import React, { useCallback, useEffect, useReducer } from 'react';

import CartContext from './contexts/cartContext';
import cartReducer, { cartInitialState } from './reducers/cartReducer';
import useBusinessEntityData from '../../hooks/useBusinessEntityData';
import { fetchGuestCartList, fetchCartList, postMergeGift } from '../../services/api/api';
import { useUserContext } from './contexts/userContext';

export default function CartContextProvider({ children }) {
  const [cartState, dispatch] = useReducer(cartReducer, cartInitialState);
  const { isB2BSite, entityData } = useBusinessEntityData();
  const {
    userState: { isLoggedIn, auth0Token, guest },
    setGuest,
  } = useUserContext();

  const fetchCartData = useCallback(async () => {
    let response = {};
    if (isLoggedIn) {
      dispatch({ type: 'LOADING_ITEMS' });
      try {
        if (isB2BSite) {
          response = await fetchCartList(auth0Token, entityData?.id);
        } else {
          response = await fetchCartList(auth0Token);
        }
        dispatch({ type: 'SET_ITEMS', payload: response.data });
      } catch (e) {
        dispatch({ type: 'UNLOADING_ITEMS' });
      }
    } else if (guest && !isB2BSite) {
      dispatch({ type: 'LOADING_ITEMS' });
      try {
        response = await fetchGuestCartList(guest.id, guest.signature);
        dispatch({ type: 'SET_ITEMS', payload: response.data });
      } catch (e) {
        dispatch({ type: 'UNLOADING_ITEMS' });
      }
    }
  }, [auth0Token, entityData?.id, guest, isB2BSite, isLoggedIn]);

  const handleGuestMerge = useCallback(async () => {
    if (guest && !isB2BSite) {
      dispatch({ type: 'LOADING_ITEMS' });
      try {
        const { code, data } = await postMergeGift(auth0Token, guest.id);
        if (code === 200) {
          dispatch({ type: 'SET_ITEMS', payload: data });
          setGuest(null);
        }
      } catch (e) {
        dispatch({ type: 'UNLOADING_ITEMS' });
      }
    }
  }, [auth0Token, guest, isB2BSite, setGuest]);

  useEffect(() => {
    if (isLoggedIn) {
      handleGuestMerge();
    }
  }, [handleGuestMerge, isLoggedIn]);

  useEffect(() => {
    fetchCartData();
  }, [fetchCartData]);

  return (
    <CartContext.Provider value={{ cartState, fetchCartData }}>{children}</CartContext.Provider>
  );
}
